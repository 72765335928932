@import "../styles/colors";

* {
	box-sizing: border-box;
}

#app,
body,
html {
	margin: 0;
	padding: 0;
	background-color: $background-color;
}
